import React from "react"
import styled from "styled-components"
import { Link as LinkTo } from "gatsby"

import Arrow from "./../../assets/images/svg/right-arrow.svg"

import { maxD } from "../../assets/styles/helpers/devices"

import { UnderlinedLink } from "../../components/global/common/links"
import { MainTitle, BigDescription } from "../../components/global/common/text"
import { LineWrapper } from "../../components/global/common/containers"
import { greenBox } from "../../components/global/common/ornaments"

import { BoxIcon } from "../../components/case-study/common/boxes"
import { UnstyledList } from "../global/common/essentials"
import { isLinkAnAnchor } from "../../assets/styles/helpers/common"

const Inner = styled(UnstyledList)`
  display: flex;
  flex-wrap: wrap;
  position: relative;

  padding: 120px 0;
  margin: -1px 0 0 -1px;

  @media ${maxD.laptop} {
    padding: 90px 0;
  }

  @media ${maxD.tablet} {
    padding: 60px 0;
  }
`

const Box = styled.li`
  position: relative;
  width: 33.33%;
  margin: 0 -1px -1px 0;

  @media ${maxD.laptop} {
    width: 50%;
  }

  @media ${maxD.tabletL} {
    width: 100%;
  }
`

const TitleBox = styled(Box)`
  padding: 0 50px 80px 0;

  @media ${maxD.tablet} {
    padding: 0 0 50px 0;
  }

  @media ${maxD.mobileL} {
    padding: 0 0 40px 0;
  }
`

const BorderedBox = styled(Box)`
  border: 1px solid var(--border-green);
  padding: 45px 35px;

  transition: background-color 200ms ease, border 200ms ease, color 200ms ease;

  &:last-child {
    ${greenBox}

    &::after {
      right: 20px;
      bottom: -25px;
    }
  }

  @media ${maxD.mobileL} {
    padding: 35px 20px;
  }

  svg {
    transition: fill 200ms ease;
  }

  &:hover {
    background-color: var(--dark-gray);
    border: 1px solid var(--dark-gray);
    color: #fff;

    a {
      color: var(--orange);

      &::after {
        background-color: var(--orange);
      }
    }

    svg {
      fill: var(--orange);
    }
  }
`

const BoxLink = styled(LinkTo)`
  position: absolute;
  width: 100%;
  height: 100%;

  top: 0;
  left: 0;
`

const BoxHeader = styled.header`
  display: flex;

  @media ${maxD.mobileL} {
    flex-direction: column;
  }
`

const SubHeader = styled.h3`
  padding: 15px 0 0 25px;
  font-size: 1.8rem;

  @media ${maxD.mobileL} {
    padding: 15px 0 0;
  }
`

const Description = styled.p`
  font-weight: 400;
  margin: 35px 0;

  @media ${maxD.desktop} {
    margin: 25px 0;
  }

  @media ${maxD.mobileL} {
    margin: 15px 0;
  }
`

const MoreLink = styled(UnderlinedLink)`
  transition: color 200ms ease;
`

const WhatWeDo = ({ data }) => {
  const header = {
    gray: data.homeWhatWeDoTitle.textGray,
    green: data.homeWhatWeDoTitle.textGreen,
  }

  const description = data.homeWhatWeDoDescription

  const list = data.homeWhatWeDoList

  return (
    <LineWrapper>
      <Inner>
        <TitleBox>
          <MainTitle>
            {`${header.gray} `}
            <span>{header.green}</span>
          </MainTitle>
          <BigDescription>{description}</BigDescription>
        </TitleBox>
        {list &&
          list.map(
            ({
              homeWhatWeDoListButton: button,
              header,
              description,
              icon,
              fieldGroupName,
            }) => (
              <BorderedBox key={`${fieldGroupName} - ${header}`}>
                <BoxLink
                  to={button.url}
                  rel="nofollow"
                  target={button.target}
                  aria-hidden="true"
                />
                <BoxHeader>
                  <BoxIcon dangerouslySetInnerHTML={{ __html: icon }} />
                  <SubHeader>{header}</SubHeader>
                </BoxHeader>
                <Description>{description}</Description>
                <MoreLink
                  to={button.url}
                  anchor={isLinkAnAnchor(button.url)}
                  target={button.target}
                  style={{
                    "--pointer-events": "none",
                  }}
                >
                  {button.title} <Arrow />
                </MoreLink>
              </BorderedBox>
            )
          )}
      </Inner>
    </LineWrapper>
  )
}

export { WhatWeDo }
